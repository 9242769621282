import { useEffect, useState } from 'react'

import { api } from '../services/api.service'

import Button from '../components/button'

import DateField from '../forms/fields/date-field'
import NumberField from '../forms/fields/number-field'
import FormModal from '../forms/form-modal'

import { TrackingBloodGeneral, TrackingBloodSpecial } from './tracking-blood'
import TrackingPhysical from './tracking-physical'

const IMPERIAL_UNITS = {
    kg: 'lbs',
    m: 'ft',
    cm: 'in',
}

export default function Tracking() {
    const [data, setData] = useState()
    const [value, setValue] = useState()
    const [date, setDate] = useState(new Date())
    const [modalOpen, setModalOpen] = useState(false)
    const [usingImperial, setUsingImperial] = useState(false)
    const [weightInput, setWeightInput] = useState({ st: 0, lbs: 0 })
    const [tab, setTab] = useState(0)

    useEffect(() => {
        loadData()
    }, [])

    useEffect(() => {
        if (!!modalOpen && modalOpen._id) {
            setValue(data.find((x) => x._id == modalOpen._id)?.data)
            setDate(new Date(data.find((x) => x._id == modalOpen._id)?.date))
        } else if (!modalOpen) {
            setModalOpen(false)
            setValue(null)
            setDate(new Date())
        }
    }, [modalOpen])

    const postNewValue = () => {
        let _value = value
        if (usingImperial) {
            if (modalOpen.type === 'weight') {
                _value = Number(weightInput.lbs) + Number(weightInput.st) * 14
                console.log('lbs', _value)
                _value = Math.round(_value / 0.022046226218) / 100
            } else if (modalOpen.type === 'height') {
                _value = Math.round(_value * 30.48) / 100
            } else if (modalOpen.type === 'waist_circumference') {
                _value = Math.round(_value / 0.03937008) / 10
            }
        }

        if (modalOpen._id) {
            api(`${process.env.REACT_APP_API_URL}/app/tracking`, { data: { _id: modalOpen._id, type: modalOpen.type, data: _value, date: date } }).then((x) => {
                loadData()
                setModalOpen(false)
                setValue(null)
                setWeightInput({ st: 0, lbs: 0 })
                setDate(new Date())
            })
        } else {
            api(`${process.env.REACT_APP_API_URL}/app/tracking`, { data: { type: modalOpen.type, data: _value, date: date } }).then((x) => {
                loadData()
                setModalOpen(false)
                setValue(null)
                setWeightInput({ st: 0, lbs: 0 })
                setDate(new Date())
            })
        }
    }

    function loadData() {
        console.log('LOADED')
        api(`${process.env.REACT_APP_API_URL}/app/tracking`).then((x) => setData(x.tracking))
    }

    if (!data) return null

    const getValue = (type) => {
        let d = data.sort((a, b) => (new Date(a) > new Date(b) ? -1 : 1)).filter((x) => x.type == type)[data.filter((x) => x.type == type)?.length - 1]
        if (d?.data != 0 && !d?.data) return '-'
        return +d?.data
    }

    return (
        <div className='animate-fade '>
            <FormModal open={!!modalOpen} setOpen={setModalOpen}>
                <div className='p-5 text-left bg-white rounded-lg'>
                    {usingImperial && modalOpen.type === 'weight' ? (
                        <>
                            <h1>Weight</h1>
                            <div className='flex flex-row items-center gap-2'>
                                <NumberField label={`(st)`} value={weightInput.st} onChange={(val) => setWeightInput({ ...weightInput, st: Number(val) })} />
                                <NumberField label={`(lbs)`} value={weightInput.lbs} onChange={(val) => setWeightInput({ ...weightInput, lbs: Number(val) })} />
                            </div>
                        </>
                    ) : (
                        <NumberField label={`${modalOpen.label} ${modalOpen.unit ? `(${usingImperial ? IMPERIAL_UNITS[modalOpen.unit] : modalOpen.unit})` : ''}`} value={value} onChange={setValue} />
                    )}

                    {modalOpen.type != 'height' && <DateField label='Date' value={date} onChange={setDate} />}
                    <div className='flex mt-4 space-x-4'>
                        <Button
                            className='bg-gray-500 hover:bg-gray-600'
                            text='Cancel'
                            onClick={() => {
                                setModalOpen(false)
                                setWeightInput({ st: 0, lbs: 0 })
                                setValue(null)
                                setDate(new Date())
                            }}
                        />
                        <Button className='flex-1 w-full' text='Save' onClick={() => postNewValue()} />
                    </div>
                </div>
            </FormModal>

            <div className='my-5 text-3xl font-black tracking-tight text-center text-slate-700'>Tracking</div>
            <div className='grid sm:grid-cols-3 mb-8 overflow-hidden bg-white rounded-lg shadow-sm gap-x-4'>
                <div onClick={() => setTab(0)} className={`${tab === 0 ? 'bg-green-600 text-white' : 'bg-white text-neutral-800'} text-lg p-4 text-center cursor-pointer`}>
                    Physical Measurements
                </div>
                <div onClick={() => setTab(1)} className={`${tab === 1 ? 'bg-green-600 text-white' : 'bg-white text-neutral-800'} text-lg p-4 text-center cursor-pointer`}>
                    General Bloods
                </div>
                <div onClick={() => setTab(2)} className={`${tab === 2 ? 'bg-green-600 text-white' : 'bg-white text-neutral-800'} text-lg p-4 text-center cursor-pointer`}>
                    Specialist Bloods
                </div>
            </div>

            {tab === 0 && <TrackingPhysical data={data} setModalOpen={setModalOpen} onGetRefresh={() => loadData()} usingImperial={usingImperial} setUsingImperial={setUsingImperial} />}

            {tab === 1 && <TrackingBloodGeneral data={data} getValue={getValue} setModalOpen={setModalOpen} />}

            {tab === 2 && <TrackingBloodSpecial data={data} getValue={getValue} setModalOpen={setModalOpen} />}
        </div>
    )
}
